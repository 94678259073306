.Select {
  position: relative;
}

.Select-control {
  width: 100%;
  display: table;
  background-color: #D7F1F5;
  color: #333;
  cursor: default;
  border-spacing: 0;
  border-collapse: separate;
  outline: none;
  overflow: hidden;
  position: relative;
}

.Select--single {
  width: 100%;
  max-width: 15.6rem;
  margin: .35rem;
  padding: 0;
  background-color: #D7F1F5;
  border: 1px solid #BEE4EA;
  border-radius: 2px;
  resize: none;
}

.Select--single.invalid {
  border-color: red;
}

.Select--single.valid {
  border-color: #2EED96;
}

.is-open > .Select-control {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #D7F1F5;
}

.is-searchable.is-open > .Select-control {
  cursor: text;
}

.Select.is-disabled > .Select-control {
  background-color: #f9f9f9;
}

.is-searchable.is-focused:not(.is-open) > .Select-control {
  cursor: text;
}

.Select-placeholder,
.Select--single > .Select-control .Select-value {
  color: #aaa;
  position: absolute;
  left: 0.3em;
  bottom: 0.25rem;
  max-width: 82%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

.has-value.Select--single > .Select-control .Select-value .Select-value-label,
.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
  color: #333;
}

.has-value.Select--single > .Select-control .Select-value a.Select-value-label,
.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label {
  cursor: pointer;
  text-decoration: none;
}

.Select-input {
  vertical-align: middle;
  display: table-cell;
}

.Select-control .Select-input:focus {
  outline: none;
}

.Select-input > input {
  background: none transparent;
  border: 0 none;
  cursor: default;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  outline: none;
}

.is-focused .Select-input > input {
  cursor: text;
}

.Select-control:not(.is-searchable) > .Select-input {
  outline: none;
}

.Select-loading-zone {
  cursor: pointer;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 1rem;
}

.Select-clear-zone {
  color: #999;
  cursor: pointer;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 17px;
}

.Select-clear-zone:hover {
  color: #000000;
}

.Select-clear {
  display: inline-block;
}

.Select-arrow-zone {
  cursor: pointer;
  display: table-cell;
  text-align: center;
  width: 1.5em;
  /* border: #000000; */
}

.Select.is-disabled .Select-arrow-zone {
  cursor: default;
  pointer-events: none;
}

.Select-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
  display: inline-block;
  vertical-align: middle;
}

.is-open .Select-arrow,
.Select-arrow-zone:hover > .Select-arrow {
  border-top-color: #000000;
}

.is-open > .Select-control .Select-arrow {
  top: -2px;
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.Select-menu-outer {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #fff;
  border-top-color: #e6e6e6;
  max-height: 6em;
  position: absolute;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.Select-menu {
  max-height: 198px;
  overflow-y: auto;
}

.Select-option {
  background-color: #fff;
  text-align: left;
  color: #666666;
  cursor: pointer;
  display: block;
  padding: 0.3em;
}

.Select-option.is-selected {
  color: #000000;
}

.Select-option.is-focused {
  background-color: #D7F1F5;
}

.Select-option.is-disabled {
  color: #cccccc;
  cursor: default;
}

@media (max-width: 596px) {
  .Select--single {
    margin: 0.35rem 0rem;
    max-width: 100%;
  }
}

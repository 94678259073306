@font-face {
  font-family: 'DINNextLTPro-Light';
  src: url('../src/assets/fonts/DINNextLTPro-Light.woff') format('woff'), url('../src/assets/fonts/DINNextLTPro-Light.otf') format('opentype');
}

@font-face {
  font-family: 'DINNextLTPro-Regular';
  src: url('../src/assets/fonts/DINNextLTPro-Regular.woff') format('woff'), url('../src/assets/fonts/DINNextLTPro-Regular.otf') format('opentype');
}

/****** CSS keylogger hack fix ******/
body input:not([value=""])[type],
body input:not([value=""])[type] *,
body input:not([value=""])[type]:focus ~ * {
  background-image: none !important;
  font-family: inherit !important;
  list-style-image: none !important;
  cursor: unset !important;
}
body input:not([value=""])[type]::before,
body input:not([value=""])[type] *::before,
body input:not([value=""])[type]:focus ~ *::before,
body input:not([value=""])[type]::after,
body input:not([value=""])[type] *::after,
body input:not([value=""])[type]:focus ~ *::after {
  content:none !important;
}
/**************************************
*            Global Styles            *
**************************************/

body {
    font-family: 'DINNextLTPro-Light', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 18px;
    font-weight: 200;
    letter-spacing: -0.5px;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

input, textarea {
    font-family: inherit;
    font-size: 1rem;
    width: 15rem;
    margin: .35rem;
    padding: .3rem;
    background-color: #D7F1F5;
    border: 1px solid #BEE4EA;
    border-radius: 2px;
    resize: none;
    box-shadow: none;
    outline: none;
}

textarea {
    font-size: 0.9em;
}

input.invalid, textarea.invalid {
    border-color: red;
}

input.valid, textarea.valid {
    border-color: #2EED96;
}

#main-menu, .footer-inner {
    max-width: 80%;
}
  
#main-menu {
    position: relative;
    display: block;
    margin: 0 auto;
    padding-top: 21px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: white;
}

.menu-navigation-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    z-index: 11;
}

.menu-navigation-container .header-logo {
    position: relative;
    display: block;
    width: auto;
    height: 29px;
}

button, .contact-button-wrapper a {
    width: 7.5rem;
    padding: .35rem 0;
    cursor: pointer;
    background-color: rgb(0, 130, 173);
    color: #FFF;
    border: 1px solid rgb(0, 130, 173);
    border-radius: 2px;
    font-size: .9rem;
    /* font-weight: 400; */
    transition: all 0.2s ease-in-out;
    text-align: center;
}

.contact-button-wrapper a {
    width: 8.9rem;
    display: block;
}

.desktop-menu ul {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0%;
}

.desktop-menu ul li {
    line-height: 1rem;
    list-style: none;
    text-decoration: none;
    color: #000;
    padding: 0 .8rem;
    border-right: 1px solid #0082AD;
    padding-top: 3px;
    cursor: pointer;
}

.desktop-menu ul li:last-of-type {
    padding-right: 0;
    border-right: 0;
}
  
.footer-social-menu {
    margin-bottom: 1rem;
}

.footer-social-menu img {
    height: 25px;
}

.footer-social-menu a {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 10px 10px 10px 0px;
    padding: 0;
    height: 25px;
    width: auto;
}

.checkbox-wrapper label a {
    border-bottom: 1px solid lightslategrey;
    display: inline-block;
    line-height: .8rem;
    color: inherit;
}

.footer-social-menu a:first-of-type {
    margin-left: 0;
}

#footer {
    background: #fff;
    font-size: 85.71429%;
    margin-top: 1.5rem;
    flex-shrink: 0;
}

#footer .footer-inner {
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;
    position: relative;
}

#footer .footer-inner .footer-links {
    border-top: 1px solid #d8dadc
}

#footer .footer-inner .footer-links .hdg-container {
    display: table;
    padding: 23px 0;
    padding-bottom: 0;
}


#footer .footer-inner .footer-links .footer-link-list li {
    display: inline-block;
    margin-right: 25px
}

#footer .footer-inner .breadcrumb {
    margin: 45px 0 20px
}

.footer-link-list {
    margin: 0;
    padding: 0;
}

.footer-inner .footer-links .hdg-container img {
    display: inline-block;
    width: auto;
    height: 18px;
    vertical-align: middle;
}

#footer .footer-inner .footer-links .hdg-container>* {
    display: flex;
    flex-wrap: wrap;
    padding-right: 15px;
    font-size: 77.7778%
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.Main {
    background-color: #EDFBFC;
    color: rgb(0, 56, 101);
    padding: 1rem 0;
    flex: 1 0 auto;
}

.main-wrapper {
    max-width: 80%;
    margin: 0 auto;
    padding: 0 1rem;
}

.section-wrapper {
    display: flex;
    justify-content: center;
}

.main-wrapper h1 {
    margin-bottom: 2rem;
    font-size: 1.5rem;
}

section h2 {
    padding-bottom: 1rem;
    border-bottom: 1px solid lightgrey;
    font-size: 1.1rem;
}

section p {
    font-size: 1rem;
}

form .input-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

form label span.mandatory-field {
    color: red;
    font-size: 1rem;
    font-weight: 400;
    position: relative;
    bottom: .1rem;
}

form button {
    margin: 0 .2rem;
}

button:hover, .contact-button-wrapper a:hover {
    background-color: #fff;
    color: rgb(0, 130, 173);  
}

form .buttons-wrapper {
    display: flex;
    justify-content: flex-end;
    margin: .35rem;
}

form button:first-of-type {
    order: 2
}

form button:last-of-type {
    order: 1
}

form .button--cancel {
    background-color: #fff;
    border: 1px solid rgb(0, 130, 173);
    color: rgb(0, 130, 173);
}

form .button--cancel:disabled, form button:disabled {
    background-color: #ffffff;
    color: #d7f1f5;
    border: 1px solid #d7f1f5;
}

.help-tools-wrapper {
    display: flex;
    margin: .5rem;
    justify-content: flex-end;
    color: red;
}

.tooltip {
    max-width: 15.5rem;
    text-align: justify;
    font-size: .8rem;
    font-style: italic;
    color: lightslategrey;
    font-weight: 400;
    padding-right: .5rem;
}

.company_name {
    position: relative;
    top: -2px;
    margin-bottom: 0;
}

.checkbox-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    margin: .5rem 0.5rem;
}

.checkbox-wrapper input {
    margin: 0 .5rem;
    min-width: 16px;
    min-height: 16px;
    width: 1em;
    height: 1em;
}

.checkbox-wrapper input[type="checkbox"] {
    margin-top: 3px;
    appearance: none;
    background: (linear, left top, left bottom, color-stop(0%, rgba(0, 130, 176, 1)), color-stop(1%, rgba(0, 130, 176, 1)), color-stop(83%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(255, 255, 255, 1)));
}

.checkbox-wrapper input[type="checkbox"]:checked {
    background: rgb(0, 130, 173);
    animation: scale-up 0.25s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.checkbox-wrapper label {
    width: 14rem;
    font-size: 85%;
    text-align: left;
}

.recaptcha div div {
    display: flex;
    justify-content: flex-end;
}

.recaptcha {
    transform: scale(0.825);
    transform-origin: right 0;
    margin-top: 1rem;
    margin-right: .35rem;
}

.error-message {
    text-align: right;
    font-size: 1rem;
    margin-top: 1rem;
    color: red;
    font-weight: bold;
}

@keyframes scale-up {
    0% {
        transform: scale(0.5);
    }
    100% {
        transform: scale(1);
    }
}

.long-desc-wrapper {
    justify-content: center;
}

.long-desc-wrapper form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.long-desc-wrapper .buttons-wrapper {
    margin-right: .2rem;
}

.long-desc-wrapper .mandatory {
    margin-right: 0;
}

.long-desc-wrapper .error-message {
    margin-right: .6rem;
}

/**************************************
*              Mobile                 *
**************************************/

@media (max-width: 596px) {
    #main-menu, .footer-inner {
        max-width: 100%;
        margin: 0;
    }

    .main-wrapper, #footer {
        max-width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    input, textarea {
        max-width: 100%;
        width: auto;
        margin: .35rem 0.1rem;
    }

    .Main {
        padding-bottom: 0rem;
    }

    form .input-wrapper {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 50% 50%;
        align-items: left;
    
    }

    .tooltip {
        max-width: 100%;
        margin-bottom: 0.5em;
    }
    
    .Main section {
        width: 100%;
    }

    .Main .help-tools-wrapper {
        width: 100%;
        margin: 0.35rem 0rem;
    }
    .Main form .buttons-wrapper {
        justify-content: space-between;
        text-align: center;
        margin: 0;
    }

    .Main form .buttons-wrapper button {
        width: 47.5%;
    }

    .checkbox-wrapper {
        width: 100%;
        justify-content: flex-start;
        margin: 0;
    }

    .checkbox-wrapper input {
        margin: 0.35em 0.35em 0.35em 0.1em;
    }

    .checkbox-wrapper label {
        margin: 0.35em 0.1em;
        width: 100%;
    }

    .recaptcha {
        width: 100%;
        transform-origin: center 0;
    }

    .recaptcha div {
        display: flex;
        justify-content: center;
    }

    .error-message {
        text-align: left;
    }
}
